import { ui } from "@clientio/rappid";
export class MoveMenuService {
    contextElement;
    createMoveMenu(node, x, y) {
        this.contextElement = node;
        const contextToolbar = new ui.ContextToolbar({
            target: { x, y },
            vertical: true,
            anchor: 'top',
            scale: 1,
            tools: [
                { action: 'integrate', content: 'Integrate' },
                { action: 'move', content: 'Move' },
                { action: 'copyOne', content: 'Copy' },
                { action: 'copyWithChildren', content: 'Copy With Children' },
            ],
        });
        contextToolbar.render();
        return contextToolbar;
    }
}
