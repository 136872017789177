import { ui } from "@clientio/rappid";
export class ContextToolbarService {
    contextElement;
    createContextMenu(node, parentElement, lastX, lastY) {
        this.contextElement = node;
        const contextToolbar = new ui.ContextToolbar({
            target: { x: lastX, y: lastY },
            vertical: true,
            anchor: 'top',
            scale: 1,
            tools: [
                { action: 'addChild', content: 'Add Child' },
                { action: 'duplicateNode', content: 'Duplicate' },
                { action: 'copyNode', content: 'Copy To...' },
                { action: 'deleteNode', content: 'Delete Node' },
                { action: 'addPrecondition', content: 'Add Precondition' },
                { action: 'refineBehaviour', content: 'Refine Behaviour' },
                { action: 'selectWithChildren', content: 'Select With Children' },
                { action: 'selectChildren', content: 'Select Children' },
                // TODO future work
                // {action: 'instantiateFunction', content: 'Instantiate Function'},
                // {action: 'instantiateInterface', content: 'Instantiate Interface'},
            ],
        });
        contextToolbar.render();
        return contextToolbar;
    }
}
